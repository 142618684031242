import React from "react";


export default function NotFound() {
    return (
        <div>
            <h1>Nothing to see here</h1>
        </div>
    )
}
